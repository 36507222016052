<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="新增项目" v-model="dialogFormVisible" width="350px">
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-form-item label="核算项目名称：" :label-width="formLabelWidth">
        <el-input v-model="form.name" size="small" placeholder="请输入核算项目名称"></el-input>
      </el-form-item>
      <el-form-item label="项目编码：" :label-width="formLabelWidth">
        <el-input v-model="form.downCode" size="small" placeholder="请输入项目编码"></el-input>
      </el-form-item>
      <el-form-item label="项目名称：" :label-width="formLabelWidth">
        <el-input v-model="form.downName" size="small" placeholder="请输入项目名称"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveProject" size="small">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { fzhsSave, newFzhsCode } from "@/api/subject"
export default {
  data() {
    return {
      dialogFormVisible:false,
      formLabelWidth: '104px',
      form:{
        name: '',
				downCode: '',
				downName: '',
      },
      id: 0,
    }
  },
  methods: {
    openDialog() {
      this.form = {
        name: '',
				downCode: '',
				downName: '',
      }
      this.dialogFormVisible = true
      newFzhsCode({fzhsId:this.id}).then(res=>{
        this.form.downCode = res.data.data.code
      })
    },
    saveProject() {
     // //console.log(this.form,'form')
      fzhsSave(this.form).then(res=>{
        if(res.data.msg == "success"){
          this.$message({
            type: 'success',
            message: '添加成功！'
          })
          this.$emit("success")
          this.dialogFormVisible = false
        }
      })
    }
  }
}
</script>

<style>

</style>