<template>
  <div class="app-container">
    <div class="left">
      <!-- <qzf-video vid="4467be53746a64108373b1aba948fc3c_4"></qzf-video> -->

      <ul class="ulStyle">
        <div class="liTitle">
          辅助核算项目
          <span v-if="$buttonStatus('fzhs_xz')" @click="addFzhs" style="font-size:16px;padding-right:20px;cursor:pointer"><i class="iconfont icon-jiahao"></i></span>
        </div>
        <li class="contentLi" v-for="(item, index) in data" :key="item.index" @click="doActive(index,item.id)" :class='{actived:currentIndex === index}'>
          <tooltip-over :content="item.name" class="wid190" refName="tooltipOver"></tooltip-over>
          <!-- <span></span> -->
          <span style="font-size:16px;cursor:pointer;margin-right:5px">
            <i class="iconfont icon-shuikuantongji" style="padding-right: 5px;" @click="bindingKM(item)" v-if="$buttonStatus('fzhs_bdkm')" ></i>
            <i class="iconfont icon-shanchu" @click="deleteFzhs(item)" v-if="$buttonStatus('fzhs_plsc')"></i>
          </span>
        </li>
      </ul>
    </div>
    <div class="right" v-if="listQuery.fzhsId">
      <div style="margin-bottom:10px;margin-left:16px;display: inline-block;">
        <el-input placeholder="请输入编码或名称" v-model="listQuery.name" style="width:200px" size="small" clearable @keyup.enter="getList" />
        <el-button size="small" type="primary" icon="Search" @click="getList">搜索</el-button>
      </div>
      <div style="float:right;margin-bottom:10px;">
        <bussinessAdd v-model:id="listQuery.fzhsId" name="新增" @success="getList"  v-if="$buttonStatus('fzhs_xz')"></bussinessAdd>
        <!-- <el-button size="small" type="danger" plain @click="batchDelete" style="margin-left:10px"  v-if="$buttonStatus('fzhs_plsc')">
          <el-icon><Delete /></el-icon> <span  >删除</span>
        </el-button> -->
        <qzf-import-back @success="init()" from="辅助核算" v-if="$buttonStatus('fzhs_dr')"></qzf-import-back>
        <!-- <el-button size="small" type="primary" plain>导入</el-button> -->
      </div>
      <el-table stripe :data="list" border v-if="this.listQuery.fzhsId" @selection-change="handleSelectionChange" :height="contentStyleObj">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" type="selection" width="55"> </el-table-column>
        <el-table-column align="center" prop="code" label="编码" width="180"> </el-table-column>
        <el-table-column align="center" prop="name" label="名称" min-width="180" show-overflow-tooltip>
          </el-table-column>
        <el-table-column align="center" prop="remark" label="备注"> </el-table-column>
        <el-table-column align="center" label="操作" min-width="200">
          <template #default="scope">
            <bussinessAdd v-model:id="listQuery.fzhsId" :name="编辑" :scopeRow="scope.row" @success="getList" v-if="$buttonStatus('fzhs_bj')"></bussinessAdd>
            <el-popconfirm v-if="$buttonStatus('fzhs_plsc')" title="是否确认删除" confirm-button-text="是" icon="InfoFilled" icon-color="red" cancel-button-text="否" @confirm="deleteRow(scope.row)" @cancel="cancelEvent">
              <template #reference>
                <el-button size="small" plain type="danger" style="margin-left:10px">
                  <el-icon><Delete /></el-icon> <span  >删除</span>
                </el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </div>
    <!-- 绑定科目弹窗 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="绑定科目" v-model="dialogVisibleBind" width="350px" :loading="loading">
      <subject-list-all v-model:subjectId="fzhsTc.subjectId"></subject-list-all>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogVisibleBind = false">取 消</el-button>
          <el-button size="small" type="primary" @click="bingSubject" :loading="loading">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <addProject ref="addProject" @success="init"></addProject>

    <el-dialog
      v-model="dialogVisibleSubject"
      :title="this.eaSubjectName"
      width="370px"
      :before-close="handleClose"
    >
      <div style="font-size:13px;color:var(--themeColor, #17a2b8);margin-bottom:5px">*{{this.subjectErr}}</div>
      <div style="font-size:13px;margin-bottom:10px">若删除此辅助核算项目，系统会将关联中的辅助核算项目更新为：</div>
      <el-select size="small" v-model="this.eaSubjectId" placeholder="请选择" clearable style="width:100%">
        <el-option v-for="item in subjectOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisibleSubject = false" size="small">取消</el-button>
          <el-button @click="makeSureDelete" size="small" type="primary">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { fzhsList , fzhsItemList , fzhsItemDel ,fzhsSave ,fzhsDel,subjectBindFzhs ,fzhsItemDelSingle ,deleteMoveFzhs} from "@/api/subject"
import bussinessAdd from "./components/bussinessAdd.vue";
import addProject from "./components/addProject";
import tooltipOver from "./components/tooltipOver.vue"
export default {
  name:'fzhs',
  components:{
    bussinessAdd,
    addProject,
    tooltipOver
  },
  data() {
    return {
      dialogVisibleBind: false,
      activeName:'first',
      fzhsActive:'客户',
      loading:false,
      data:[],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      list:[],
      listQuery:{
        page:1,
        limit:10,
        fzhsId:undefined
      },
      fzhsTc: {
        fzhsId: 0,
        subjectId: 0
      },
      userTotal:2,
      currentIndex:-1,
      name:'',
      ids: [],
      total:0,
      isShowTooltip:true,
      dialogVisibleSubject:false,
      subjectOptions:[],
      eaSubjectId:0,
      subjectErr:'',
      eaSubjectName:'',
      oldSubjectId:0,
    }
  },
  created() {
    this.init()
    this.contentStyleObj= this.$getHeight(260)
  },
  methods:{
    init(){
      fzhsList({}).then(res=>{
        this.data = res.data.data.list
      })
    },
    getList(){
      fzhsItemList(this.listQuery).then(res=>{
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    doActive(index,id){
      this.currentIndex = index
      this.listQuery.fzhsId = id
      this.getList()
    },
    //绑定科目
    bindingKM(item) {
      this.dialogVisibleBind = true
      this.fzhsTc.subjectId = 0
      this.fzhsTc.fzhsId = item.id
    },
    deleteRow(row){
      fzhsItemDelSingle(row).then(res=>{
        if(res.data.msg == "success"){
          if(res.data.data.err){
            this.oldSubjectId = row.id
            this.eaSubjectName = row.name
            this.subjectErr = res.data.data.err.split('，')[0]
            // 弹框
            this.subjectOptions = res.data.data.list
            this.eaSubjectId = this.subjectOptions[0].id
            this.dialogVisibleSubject = true
          }else{
            this.getList()
            this.$qzfMessage("删除成功")
            this.$store.dispatch("commons/getSubjects")
            this.$store.dispatch("commons/getBusinessType")
          }
        }
      })
    },
    makeSureDelete(){
      let param = {
        fzhsItemId:this.oldSubjectId,
        fzhsItemMoveId:this.eaSubjectId,
      }
      deleteMoveFzhs(param).then(res => {
        if(res.data.msg == "success"){
          this.dialogVisibleSubject = false
          this.$qzfMessage("删除成功")
          this.getList()
          this.$store.dispatch("commons/getSubjects")
          this.$store.dispatch("commons/getBusinessType")
        }
      })
    },
    handleSelectionChange(val) {
      this.ids = val.map(v=>{
        return {id: v.id}
      })
    },
    //绑定科目确定
    bingSubject() {
      this.loading = true
      subjectBindFzhs(this.fzhsTc).then(res => {
        this.loading = false
        if(res.data.msg == "success") {
          this.$qzfMessage("绑定成功！")
          this.getList()
          this.dialogVisibleBind = false
          this.$store.dispatch("commons/getSubjects")
          this.$store.dispatch("commons/getBusinessType")   
        }
      })
    },
    batchDelete(){
      if(this.ids.length == 0){
        this.$qzfMessage("请至少选择一个",1)
      }else{
        fzhsItemDel(this.ids).then(res=>{
          if(res.data.msg == "success"){
            this.getList()
            this.$qzfMessage("删除成功")
          }
        })
      }
    },
    addFzhs() {
      this.$refs.addProject.openDialog()
    },
    // addFzhs(){
    //   this.$prompt('请输入辅助核算名称', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //   }).then(({ value }) => {
    //     fzhsSave({name:value}).then(res=>{
    //       if(res.data.msg == "success"){
    //         this.init()
    //         this.$message({
    //           type: 'success',
    //           message: '已添加辅助核算名称为: ' + value
    //         })
    //       }
    //     })
    //     ;
    //   });
    // },
    deleteFzhs(item){
      this.$confirm('是否确认删除'+item.name+'辅助核算项目, 是否继续?', '提示', {
          confirmButtonText: ' 确定',
          cancelButtonText: '取消',
          type: 'warning',
          confirmButtonClass:'iconfont icon-shanchu'

        }).then(() => {
          fzhsDel({id:item.id}).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("删除成功")
              this.init()
              this.getList()
            }
          })
        });
      
    },
  }
}
</script>

<style lang="scss" scoped>
.el-table{
  width: 99%;
  float: right;
}
.actived{
  background-color: #c3e5e3!important;
  font-weight: 600;
}
.liTitle{
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 11px;
  margin-top: 10px;
  text-align: left;
}
.ulStyle{
  margin-right: 20px;
  li{
    text-align: left;
    cursor: pointer;
    height: 26px;
    font-size: 13px;
    line-height: 26px;
    border-radius: 7px;
  }
}
li:hover{
  background-color: #f0f9f8;
  // #f0f9f8
}
.left{
  width: 15%;
  min-height: calc(94vh - 100px);
  display:inline-block;
  // float:left;
  border-right:1px solid #e8e8e8;
}
.right{
  float: right;
  width: 85%;
}
.contentLi{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.li_left{
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>